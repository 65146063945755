import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './Main.module.scss'
import altIcon from '../../imgs/altIcon.svg'
import { useTranslation } from 'react-i18next';
import OrdersTable from '../../components/OrdersTable';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Graph from '../../components/Graph';
import { Drawer } from '@mui/material';
import BuySellDrawer from '../../components/BuySellDrawer';
import MobileAssetsDrawer from '../../components/MobileAssetsDrawer';
import moment from 'moment';
import MobileOrderDrawer from '../../components/MobileOrderDrawer';
import EditOrderDrawer from '../../components/EditOrderDrawer';
import OrdersTableHistory from '../../components/OrdersTableHistory';
import assetsLogo from "../../components/assetsLogo.json";
import assetsLogoByName from "../../components/assetsLogoByName.json";

export default function Main({ activeAsset, activePrice, setActivePrice, setActiveAsset, theme, eurToUsd, usdToEur, favorites, toggleFavorite,AssetsList,account,setAccount,setProfit ,setSnackType,setSnackMessage,handleClick,applications }) {
    const handleImgError = (e) => {
        e.target.src = altIcon; // Подставляем альтернативную иконку при ошибке
    };
    const { t, i18n } = useTranslation()
    const [currentRecords, setCurrentRecords] = useState([]);
    const [history, setHistory] = useState([]);
    const [intervalLabel, setIntervalLabel] = useState('1m');
    const [openAssetsDrawer, setOpenAssetsDrawer] = useState(false)

    const toggleAssetsDrawer = (newOpen) => () => {
        setOpenAssetsDrawer(newOpen)
    };

    const [interval, setIntervall] = useState(60);

    const handleIntervalChange = (newInterval, label) => {
        setIntervall(newInterval);
        setIntervalLabel(label)
    };
    const [isOrdersBig, setIsOrdersBig] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [openEditDrawer, setOpenEditOrder] = useState(false);
    const [selectedTab, setSelectedTab] = useState('open');
    const [selectedMobileTab, setSelectedMobileTab] = useState('open');
    const [selectedIntervalTab, setSelectedIntervalTab] = useState('1m');
    const [backgroundTableStyle, setBackgroundTableStyle] = useState({});
    const [backgroundMobileStyle, setBackgroundMobileStyle] = useState({});
    const [backgroundIntervalStyle, setBackgroundIntervalStyle] = useState({});
    const [backgroundTradingStyle, setBackgroundTradingStyle] = useState({});
    const openMobile = useRef(null);
    const deferredMobile = useRef(null);
    const closeMobile = useRef(null);
    const open = useRef(null);
    const deferred = useRef(null);
    const close = useRef(null);
    const min = useRef(null);
    const fivemin = useRef(null);
    const fifteenmin = useRef(null);
    const thirtymin = useRef(null);
    const hour = useRef(null);
    const fourhour = useRef(null);
    const day = useRef(null);
    const week = useRef(null);
    const month = useRef(null);
    const year = useRef(null);
    const trading = useRef(null);
    const orders = useRef(null);
    const [selectedOrderTab, setSelectedOrderTab] = useState('buy');
    const [selectedTradingTab, setSelectedTradingTab] = useState('trading');
    const [backgroundOrderStyle, setBackgroundOrderStyle] = useState({});
    const buyOrder = useRef(null);
    const sellOrder = useRef(null);
    const inputRef = useRef(null);

    const toggleEditDrawer = (newOpen,element) => () =>{
        setOpenEditOrder(newOpen)
        setCurrentRow(element)
    }

    const handleSearchClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleTabTableChange = (tab) => {
        setSelectedTab(tab)
    };
    const handleTabMobileChange = (tab) => {
        setSelectedMobileTab(tab)
    };


    const updateBackgroundPositionMobile = () => {
        let targetTab;
        switch (selectedMobileTab) {
            case 'open':
                targetTab = openMobile.current;
                break;
            case 'deferred':
                targetTab = deferredMobile.current;
                break;
            case 'close':
                targetTab = closeMobile.current;
                break;
            default:
                targetTab = openMobile.current;
        }

        if (targetTab) {
            setBackgroundMobileStyle({
                width: targetTab.offsetWidth,
                height: targetTab.offsetHeight,
                transform: `translateX(${targetTab.offsetLeft - 3}px)`,
                transition: 'background-color 0.3s ease, transform 0.3s ease', // Добавляем анимацию
            });
        }
    };
    useEffect(() => {
        updateBackgroundPositionMobile();
    }, [selectedMobileTab]);


    const handleTabOrderChange = (tab) => {
        setSelectedOrderTab(tab);
    };

    const handleTabIntervalChange = (tab) => {
        setSelectedIntervalTab(tab)
    };
    const handleTabTradingChange = (tab) => {
        setSelectedTradingTab(tab)
    };
    const updateBackgroundPositionInterval = () => {
        let targetTab;
        switch (selectedIntervalTab) {
            case '1m':
                targetTab = min.current;
                break;
            case '5m':
                targetTab = fivemin.current;
                break;
            case '15m':
                targetTab = fifteenmin.current;
                break;
            case '30m':
                targetTab = thirtymin.current;
                break;
            case '1h':
                targetTab = hour.current;
                break;
            case '4h':
                targetTab = fourhour.current;
                break;
            case '1d':
                targetTab = day.current;
                break;
            case '1w':
                targetTab = week.current;
                break;
            case '1M':
                targetTab = month.current;
                break;
            case '1Y':
                targetTab = year.current;
                break;
            default:
                targetTab = min.current;
        }

        if (targetTab) {
            setBackgroundIntervalStyle({
                width: targetTab.offsetWidth,
                height: targetTab.offsetHeight,
                transform: `translateX(${targetTab.offsetLeft - 3}px)`,
                transition: 'background-color 0.3s ease, transform 0.3s ease', // Добавляем анимацию
            });
        }
    };
    const updateBackgroundPositionTrading = () => {
        let targetTab;
        switch (selectedTradingTab) {
            case 'trading':
                targetTab = trading.current;
                break;
            case 'orders':
                targetTab = orders.current;
                break;
            default:
                targetTab = trading.current;
        }

        if (targetTab) {
            setBackgroundTradingStyle({
                width: targetTab.offsetWidth,
                height: targetTab.offsetHeight,
                transform: `translateX(${targetTab.offsetLeft - 3}px)`,
                transition: 'background-color 0.3s ease, transform 0.3s ease', // Добавляем анимацию
            });
        }
    };
    useEffect(() => {
        updateBackgroundPositionTrading();
    }, [selectedTradingTab]);
    useEffect(() => {
        updateBackgroundPositionInterval();
    }, [selectedIntervalTab]);

    const updateBackgroundPositionTable = () => {
        let targetTab;
        switch (selectedTab) {
            case 'open':
                targetTab = open.current;
                break;
            case 'deferred':
                targetTab = deferred.current;
                break;
            case 'close':
                targetTab = close.current;
                break;
            default:
                targetTab = open.current;
        }

        if (targetTab) {
            setBackgroundTableStyle({
                width: targetTab.offsetWidth,
                height: targetTab.offsetHeight,
                transform: `translateX(${targetTab.offsetLeft - 3}px)`,
                transition: 'background-color 0.3s ease, transform 0.3s ease', // Добавляем анимацию
            });
        }
    };
    useEffect(() => {
        updateBackgroundPositionTable();
    }, [selectedTab]);

    useEffect(() => {
            axios.get(process.env.REACT_APP_test_url + `/api/account/assets/user/${account?.Id}`, {
                headers: {
                    'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                }
            })
                .then((res) => {
                    setCurrentRecords(res.data?.reverse());
                })
                .catch((err) => {
                    console.log(err);
                })
            const timer = setInterval(() => {
                axios.get(process.env.REACT_APP_test_url + `/api/account/assets/user/${account?.Id}/history`, {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                }).then((result) => {
                    setHistory(result.data.filter(el => el.Status == 'CLOSED' || el.Status == 'KILLED'))
                }).catch(e => console.log(e))
                axios.get(process.env.REACT_APP_test_url + `/api/account/assets/user/${account?.Id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                })
                    .then((res) => {
                        setCurrentRecords(res.data?.reverse());
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }, 2000)
            return()=>{
                clearInterval(timer)
            }
    }, [account])

    const Close = async (row) => {
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_test_url + `/api/account/assets/${row.AssetId}/close`,
                {
                    user_id: account?.Id,
                    asset_id: row.Id
                },
                {
                    headers: {
                        'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
                    }
                }
            );
            if (data) {
                console.log('success')
                handleClick();
                setSnackMessage(`${t('CloseSuccess')} ${t('Profit') + ': ' + parseFloat(row.Profit).toFixed(2) + `${secureLocalStorage.getItem('selected_account')?.AccountCurrency === "EUR"? "€": "$"}`}`);
                setSnackType('success');
            }
        } catch (e) {
          handleClick();
          setSnackMessage(e.response.data.message);
          setSnackType("error");
        }
    }
    const getLiquidationPrice = (row) => {
        const user = secureLocalStorage.getItem("user");
        const currency = account?.AccountCurrency || "USD"; // Предполагаем, что по умолчанию USD
        const currencyFactor = currency === "EUR" ? eurToUsd : 1;
        const symbol = currency === "EUR" ? "€" : "$";
        
        const startPrice = parseFloat(row.original.StartPrice) * currencyFactor;
        const leverage = parseFloat(row.original.Leverage);
        // Если кредитное плечо равно 1, ликвидационная цена не применяется
        if (leverage === 1 || row.original.StopLoss) {
            return '-- --';
        }
    
        let liquidationPrice;
    
        if (row.original.Status === 'BUY') {
            // Формула для длинной позиции (BUY)
            liquidationPrice = startPrice * (1 - 1 / leverage);
        } else {
            // Формула для короткой позиции (SELL)
            liquidationPrice = startPrice * (1 + 1 / leverage);
        }
    
        // Форматируем результат до 4 знаков после запятой
        return `${liquidationPrice.toFixed(4)} ${symbol}`;
    }

  const columns = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: t("OrderId"),
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("OrderId")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        grow: 1,
        size: 40,
      },
      {
        accessorKey: "TimeInForceDate",
        header: t("Open Date"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Open Date")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {moment(row.original.TimeInForceDate).format("YYYY-MM-DD")}
              <span style={{ color: theme === "dark" ? "#51525C" : "#9CA3AF" }}>
                {" "}
                {moment(row.original.TimeInForceDate).format("HH:mm")}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Title",
        header: t("Instrument"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Instrument")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span style={{ cursor: "pointer" }}>
              {account?.AccountCurrency == "EUR"
                ? row.original.Subtitle.slice(1, -1).replace("/USD", "/EUR")
                : row.original.Subtitle.slice(1, -1)}
            </span>
          );
        },
      },
      {
        accessorKey: "Status",
        header: t("Type"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Type")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={
                row.original.Status === "BUY"
                  ? {
                      color: "#00B4A0",
                      backgroundColor: "rgba(0, 180, 160, 0.08)",
                      padding: "0.4vh 1.2vh",
                      borderRadius: "100px",
                    }
                  : row.original.Status === "SELL"
                  ? {
                      color: "#F63D68",
                      backgroundColor: "rgba(246, 61, 104, 0.08)",
                      padding: "0.4vh 1.2vh",
                      borderRadius: "100px",
                    }
                  : {}
              }
            >
              {row.original.Status.slice(0, 1) +
                row.original.Status.slice(1).toLowerCase()}
            </span>
          );
        },
      },
      {
        accessorKey: "Leverage",
        header: t("Leverage"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Leverage")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={{
                backgroundColor: "rgba(233, 119, 14, 0.08)",
                color: "#E9770E",
                padding: "0.4vh 1.2vh",
                borderRadius: "100px",
              }}
            >
              X{row.original.Leverage}
            </span>
          );
        },
      },
      {
        accessorKey: "Price",
        header: t("Margin"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Margin")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {account?.AccountCurrency == "EUR" ? "€" : "$"}
              {(
                row.original.Price *
                (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
              )?.toFixed(5)}
            </span>
          );
        },
      },
      {
        accessorKey: "StartPrice",
        header: t("EntryPrice"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("EntryPrice")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {account?.AccountCurrency == "EUR" ? "€" : "$"}
              {(
                row.original.StartPrice *
                (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
              )?.toFixed(5)}
            </span>
          );
        },
      },
      {
        accessorKey: "Take",
        header: t("Take-Profit"),
        grow: 1,
        size: 40,
        enableSorting: false,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Take-Profit")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
              onClick={toggleEditDrawer(true, row.original)}
            >
              <span style={{ fontSize: "1.2vh", color: "#3F3F46" }}>
                {row.original.TakeProfit ? (
                  <span style={{ color: "#00B4A0" }}>
                  {(Math.trunc(
                      row.original.TakeProfit *
                      (account?.AccountCurrency == "EUR" ? eurToUsd : 1) * 10000
                    ) / 10000
                  )}
                  </span>
                ) : (
                  <span style={{ color: "#00B4A0" }}>
                    0 {account?.AccountCurrency == "EUR" ? "€" : "$"}
                  </span>
                )}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Stop",
        header: t("Stop-Loss"),
        grow: 1,
        size: 40,
        enableSorting: false,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Stop-Loss")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
              onClick={toggleEditDrawer(true, row.original)}
            >
              <span style={{ fontSize: "1.2vh", color: "#3F3F46" }}>
                {row.original.StopLoss ? (
                  <span style={{ color: "#F63D68" }}>
                    {(
                      row.original.StopLoss *
                      (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                    ).toFixed()}
                  </span>
                ) : (
                  <span style={{ color: "#F63D68" }}>
                    0 {account?.AccountCurrency == "EUR" ? "€" : "$"}
                  </span>
                )}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Liquidation",
        header: t("Liquidation"),
        grow: 1,
        size: 40,

        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Liquidation")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={
                row.original.Leverage == 1 || row.original.StopLoss
                  ? { color: "#9F9C9C" }
                  : { color: "#F63D68" }
              }
            >
              {getLiquidationPrice(row)}
            </span>
          );
        },
      },
      // {
      //     accessorKey: 'Market',
      //     header: t('MarketPrice'),
      //     grow: 1,
      //     size: 40,
      //     Header: () => (
      //         <div className={styles.header_cell}>
      //             <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('MarketPrice')}</span>
      //             <div className={styles.sort_icon}>
      //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
      //                     <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      //                 </svg>
      //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
      //                     <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      //                 </svg>
      //             </div>
      //         </div>
      //     ),
      //     Cell: ({ row }) => {
      //         return (
      //             <span>
      //                 {
      //                     account?.AccountCurrency == "EUR"
      //                         ?
      //                         "€"
      //                         :
      //                         "$"
      //                 }
      //                 {(parseFloat(row.original.MarketPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(4)}
      //             </span>
      //         )
      //     },
      // },
      {
        accessorKey: "Profit",
        header: t("Profit"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Profit")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {row.original.Status === "BUY" ? (
                <div
                  style={
                    parseFloat(row.original.Profit) > 0
                      ? { color: "#00B4A0" }
                      : { color: "#F63D68" }
                  }
                >
                  {parseFloat(row.original.Profit) > 0 ? "+ " : "- "}
                  {account?.AccountCurrency == "EUR" ? "€" : "$"}
                  {parseFloat(row.original.Profit) > 0
                    ? parseFloat(
                        row.original.Profit *
                          (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                      ).toFixed(1)
                    : parseFloat(
                        row.original.Profit *
                          (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                      )
                        .toFixed(1)
                        .slice(1)}
                </div>
              ) : (
                <div
                  style={
                    parseFloat(row.original.Profit) > 0
                      ? { color: "#00B4A0" }
                      : { color: "#F63D68" }
                  }
                >
                  {parseFloat(row.original.Profit) > 0 ? "+ " : "- "}
                  {account?.AccountCurrency == "EUR" ? "€" : "$"}
                  {parseFloat(row.original.Profit) > 0
                    ? parseFloat(
                        row.original.Profit *
                          (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                      ).toFixed(1)
                    : parseFloat(
                        row.original.Profit *
                          (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                      )
                        .toFixed(1)
                        .slice(1)}
                </div>
              )}
            </span>
          );
        },
      },
      {
        accessorKey: "Actions",
        header: "",
        grow: 1,
        size: 40,
        enableSorting: false,

        Cell: ({ row }) => {
          return (
            <button
              onClick={() => {
                Close(row.original);
              }}
              style={
                row.original.Status === "BUY"
                  ? parseFloat(row.original.Profit) > 0
                    ? {
                        cursor: "pointer",
                        background: "#00B4A0",
                        fontSize: "1.6vh",
                        color: "#FCFCFC",
                        padding: "0.8vh 1.2vh",
                        border: "none",
                        borderRadius: "1004px",
                        fontFamily: "'Inter',sans-serif",
                      }
                    : {
                        cursor: "pointer",
                        background: "#F63D68",
                        fontSize: "1.6vh",
                        color: "#FCFCFC",
                        padding: "0.8vh 1.2vh",
                        border: "none",
                        borderRadius: "1004px",
                        fontFamily: "'Inter',sans-serif",
                      }
                  : parseFloat(row.original.Profit) * -1 > 0
                  ? {
                      cursor: "pointer",
                      background: "#F63D68",
                      fontSize: "1.6vh",
                      color: "#FCFCFC",
                      padding: "0.8vh 1.2vh",
                      border: "none",
                      borderRadius: "1004px",
                      fontFamily: "'Inter',sans-serif",
                    }
                  : {
                      cursor: "pointer",
                      background: "#00B4A0",
                      fontSize: "1.6vh",
                      color: "#FCFCFC",
                      padding: "0.8vh 1.2vh",
                      border: "none",
                      borderRadius: "1004px",
                      fontFamily: "'Inter',sans-serif",
                    }
              }
            >
              Close
            </button>
          );
        },
      },
    ],
    [i18n.language, AssetsList]
  );

  const columnsDeferred = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: t("OrderId"),
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("OrderId")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        grow: 1,
        size: 40,
      },
      {
        accessorKey: "TimeInForceDate",
        header: t("Open Date"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Open Date")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {moment(row.original.TimeInForceDate).format("YYYY-MM-DD")}
              <span style={{ color: theme === "dark" ? "#51525C" : "#9CA3AF" }}>
                {" "}
                {moment(row.original.TimeInForceDate).format("HH:mm")}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Title",
        header: t("Instrument"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Instrument")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span style={{ cursor: "pointer" }}>
              {account?.AccountCurrency == "EUR"
                ? row.original.Subtitle.slice(1, -1).replace("/USD", "/EUR")
                : row.original.Subtitle.slice(1, -1)}
            </span>
          );
        },
      },
      {
        accessorKey: "Status",
        header: t("Type"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Type")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={
                row.original.Status === "BUYD"
                  ? {
                      color: "#00B4A0",
                      backgroundColor: "rgba(0, 180, 160, 0.08)",
                      padding: "0.4vh 1.2vh",
                      borderRadius: "100px",
                    }
                  : row.original.Status === "SELLD"
                  ? {
                      color: "#F63D68",
                      backgroundColor: "rgba(246, 61, 104, 0.08)",
                      padding: "0.4vh 1.2vh",
                      borderRadius: "100px",
                    }
                  : {
                      backgroundColor: "rgba(233, 119, 14, 0.08)",
                      color: "#E9770E",
                      padding: "0.4vh 1.2vh",
                      borderRadius: "100px",
                    }
              }
            >
              {row.original.Status.slice(0, 1) +
                row.original.Status.slice(1, -1).toLowerCase()}
            </span>
          );
        },
      },
      {
        accessorKey: "Price",
        header: t("Margin"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Margin")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {account?.AccountCurrency == "EUR" ? "€" : "$"}
              {parseFloat(
                (row.original.OpenWhen *
                  (account?.AccountCurrency == "EUR" ? eurToUsd : 1) *
                  row.original.Count *
                  row.original.LotSize) /
                  row.original.Leverage
              ).toFixed(5)}
            </span>
          );
        },
      },
      {
        accessorKey: "StartPrice",
        header: t("EntryPrice"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("EntryPrice")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {account?.AccountCurrency == "EUR" ? "€" : "$"}
              {(
                row.original.OpenWhen *
                (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
              )?.toFixed(5)}
            </span>
          );
        },
      },
      // {
      //     accessorKey: 'Liquidation',
      //     header: t('Liquidation'),
      //     size: 110,
      //     Header: () => (
      //         <div className={styles.header_cell}>
      //             <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Liquidation')}</span>
      //             <div className={styles.sort_icon}>
      //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
      //                     <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      //                 </svg>
      //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
      //                     <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      //                 </svg>
      //             </div>
      //         </div>
      //     ),
      //     Cell: ({ row }) => {
      //         return (
      //             <span style={row.original.Leverage !== 1 ? { color: '#F63D68' } : { color: '#9F9C9C' }}>
      //                 {getLiquidationPrice(row)}
      //             </span>
      //         )
      //     },
      // },
      {
        accessorKey: "Leverage",
        header: t("Leverage"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Leverage")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={{
                backgroundColor: "rgba(233, 119, 14, 0.08)",
                color: "#E9770E",
                padding: "0.4vh 1.2vh",
                borderRadius: "100px",
              }}
            >
              X{row.original.Leverage}
            </span>
          );
        },
      },
      // {
      //     accessorKey: 'Profit',
      //     header: t('Profit'),
      //     size: 80,
      //     Header: () => (
      //         <div className={styles.header_cell}>
      //             <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Profit')}</span>
      //             <div className={styles.sort_icon}>
      //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
      //                     <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      //                 </svg>
      //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
      //                     <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      //                 </svg>
      //             </div>
      //         </div>
      //     ),
      //     Cell: ({ row }) => {
      //         return (
      //             <span>
      //                 {
      //                     row.original.Status === 'BUY' ?
      //                         <div style={parseFloat(row.original.Profit) > 0 ? { color: '#00B4A0' } : { color: '#F63D68' }}>
      //                             {parseFloat(row.original.Profit) > 0 ? '+ ' : '- '}
      //                             {
      //                                 account?.AccountCurrency == "EUR"
      //                                     ?
      //                                     "€"
      //                                     :
      //                                     "$"
      //                             }
      //                             {parseFloat((row.original.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(2)}
      //                         </div>
      //                         :
      //                         <div style={parseFloat(row.original.Profit) > 0 ? { color: '#00B4A0' } : { color: '#F63D68' }}>
      //                             {parseFloat(row.original.Profit) > 0 ? '+ ' : '- '}
      //                             {
      //                                 account?.AccountCurrency == "EUR"
      //                                     ?
      //                                     "€"
      //                                     :
      //                                     "$"
      //                             }
      //                             {parseFloat((row.original.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(2).slice(1)}
      //                         </div>
      //                 }
      //             </span>
      //         )
      //     },
      // },
      {
        accessorKey: "Take",
        header: t("Take-Profit"),
        grow: 1,
        size: 40,
        enableSorting: false,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Take-Profit")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
              onClick={toggleEditDrawer(true, row.original)}
            >
              <span style={{ fontSize: "1.2vh", color: "#3F3F46" }}>
                {row.original.TakeProfit ? (
                  <span style={{ color: "#00B4A0" }}>
                  {(Math.trunc(
                      row.original.TakeProfit *
                      (account?.AccountCurrency == "EUR" ? eurToUsd : 1) * 10000
                    ) / 10000
                  )}
                  </span>
                ) : (
                  <span style={{ color: "#00B4A0" }}>
                    0 {account?.AccountCurrency == "EUR" ? "€" : "$"}
                  </span>
                )}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Stop",
        header: t("Stop-Loss"),
        grow: 1,
        size: 40,
        enableSorting: false,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Stop-Loss")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
              onClick={toggleEditDrawer(true, row.original)}
            >
              <span style={{ fontSize: "1.2vh", color: "#3F3F46" }}>
                {row.original.StopLoss ? (
                  <span style={{ color: "#F63D68" }}>
                    {(
                      row.original.StopLoss *
                      (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                    ).toFixed()}
                  </span>
                ) : (
                  <span style={{ color: "#F63D68" }}>
                    0 {account?.AccountCurrency == "EUR" ? "€" : "$"}
                  </span>
                )}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Actions",
        header: "",
        grow: 1,
        size: 40,
        grow: 1,
        enableSorting: false,

        Cell: ({ row }) => {
          return (
            <button
              onClick={() => {
                Sell(row.original);
              }}
              style={
                row.original.Status === "BUY"
                  ? parseFloat(row.original.Profit) > 0
                    ? {
                        cursor: "pointer",
                        background: "#00B4A0",
                        fontSize: "1.6vh",
                        color: "#FCFCFC",
                        padding: "0.8vh 1.2vh",
                        border: "none",
                        borderRadius: "1004px",
                        fontFamily: "'Inter',sans-serif",
                      }
                    : {
                        cursor: "pointer",
                        background: "#F63D68",
                        fontSize: "1.6vh",
                        color: "#FCFCFC",
                        padding: "0.8vh 1.2vh",
                        border: "none",
                        borderRadius: "1004px",
                        fontFamily: "'Inter',sans-serif",
                      }
                  : parseFloat(row.original.Profit) * -1 > 0
                  ? {
                      cursor: "pointer",
                      background: "#F63D68",
                      fontSize: "1.6vh",
                      color: "#FCFCFC",
                      padding: "0.8vh 1.2vh",
                      border: "none",
                      borderRadius: "1004px",
                      fontFamily: "'Inter',sans-serif",
                    }
                  : {
                      cursor: "pointer",
                      background: "#00B4A0",
                      fontSize: "1.6vh",
                      color: "#FCFCFC",
                      padding: "0.8vh 1.2vh",
                      border: "none",
                      borderRadius: "1004px",
                      fontFamily: "'Inter',sans-serif",
                    }
              }
            >
              Close
            </button>
          );
        },
      },
    ],
    [i18n.language, AssetsList]
  );
  const columnsClosed = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: t("OrderId"),
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("OrderId")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        grow: 1,
        size: 40,
      },
      {
        accessorKey: "TimeInForceDate",
        header: t("Open Date"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Open Date")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {moment(row.original.TimeInForceDate).format("YYYY-MM-DD")}
              <span style={{ color: theme === "dark" ? "#51525C" : "#9CA3AF" }}>
                {" "}
                {moment(row.original.TimeInForceDate).format("HH:mm")}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Date",
        header: t("Date"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Date")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span>
              {moment(row.original.Date).format("YYYY-MM-DD")}
              <span style={{ color: theme === "dark" ? "#51525C" : "#9CA3AF" }}>
                {" "}
                {moment(row.original.Date).format("HH:mm")}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Title",
        header: t("Instrument"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Instrument")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span style={{ cursor: "pointer" }}>
              {account?.AccountCurrency == "EUR"
                ? row.original.SubTitle.slice(1, -1).replace("/USD", "/EUR")
                : row.original.SubTitle.slice(1, -1)}
            </span>
          );
        },
      },
      {
        accessorKey: "OrderType",
        header: t("Type"),
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Type")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        grow: 1,
        size: 40,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span
              style={
                row.original.OrderType?.includes("BUY")
                  ? {
                      color: "#00B4A0",
                      backgroundColor: "rgba(0, 180, 160, 0.08)",
                      padding: "0.4vh 1.2vh",
                      borderRadius: "100px",
                    }
                  : row.original.OrderType?.includes("SELL")
                  ? {
                      color: "#F63D68",
                      backgroundColor: "rgba(246, 61, 104, 0.08)",
                      padding: "0.4vh 1.2vh",
                      borderRadius: "100px",
                    }
                  : {}
              }
            >
              {row.original.OrderType?.includes("D")
                ? row.original.OrderType?.slice(0, -1)
                : row.original?.OrderType?.slice(0, 1) +
                  row.original.OrderType?.slice(1).toLowerCase()}
            </span>
          );
        },
      },
      {
        accessorKey: "Leverage",
        header: t("Leverage"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Leverage")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span
              style={{
                backgroundColor: "rgba(233, 119, 14, 0.08)",
                color: "#E9770E",
                padding: "0.4vh 1.2vh",
                borderRadius: "100px",
              }}
            >
              X{row.original.Leverage}
            </span>
          );
        },
      },
      // {
      //     accessorKey: 'Price',
      //     header: t('Amount'),
      //     Header: () => (
      //         <div className={styles.header_cell}>
      //             <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Amount')}</span>
      //             <div className={styles.sort_icon}>
      //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
      //                     <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      //                 </svg>
      //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
      //                     <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      //                 </svg>
      //             </div>
      //         </div>
      //     ),
      //     grow: 1,
      //     size: 40,
      //     Cell: ({ renderedCellValue, row }) => {
      //         return (
      //             <span>
      //                 {
      //                     account?.AccountCurrency == "EUR"
      //                         ?
      //                         "€"
      //                         :
      //                         "$"
      //                 }
      //                 {(row.original.Price * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(5)}
      //             </span>
      //         )
      //     },
      // },
      {
        accessorKey: "StartPrice",
        header: t("EntryPrice"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("EntryPrice")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span>
              {account?.AccountCurrency == "EUR" ? "€" : "$"}
              {(
                row.original.StartPrice *
                (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
              )?.toFixed(5)}
            </span>
          );
        },
      },
      {
        accessorKey: "EndPrice",
        header: t("EndPrice"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("EndPrice")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span>
              {account?.AccountCurrency == "EUR" ? "€" : "$"}
              {(
                row.original.EndPrice *
                (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
              )?.toFixed(5)}
            </span>
          );
        },
      },
      {
        accessorKey: "Take",
        header: t("Take-Profit"),
        grow: 1,
        size: 40,
        enableSorting: false,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Take-Profit")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
              onClick={toggleEditDrawer(true, row.original)}
            >
              <span style={{ fontSize: "1.2vh", color: "#3F3F46" }}>
                {row.original.TakeProfit ? (
                  <span style={{ color: "#00B4A0" }}>
                    {(Math.trunc(
                        row.original.TakeProfit *
                        (account?.AccountCurrency == "EUR" ? eurToUsd : 1) * 10000
                      ) / 10000
                    )}
                  </span>
                ) : (
                  <span style={{ color: "#00B4A0" }}>
                    0 {account?.AccountCurrency == "EUR" ? "€" : "$"}
                  </span>
                )}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Stop",
        header: t("Stop-Loss"),
        grow: 1,
        size: 40,
        enableSorting: false,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Stop-Loss")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
              onClick={toggleEditDrawer(true, row.original)}
            >
              <span style={{ fontSize: "1.2vh", color: "#3F3F46" }}>
                {row.original.StopLoss ? (
                  <span style={{ color: "#F63D68" }}>
                    {(
                      row.original.StopLoss *
                      (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                    ).toFixed()}
                  </span>
                ) : (
                  <span style={{ color: "#F63D68" }}>
                    0 {account?.AccountCurrency == "EUR" ? "€" : "$"}
                  </span>
                )}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "Profit",
        header: t("Profit"),
        grow: 1,
        size: 40,
        Header: () => (
          <div className={styles.header_cell}>
            <span
              style={{ fontWeight: "500", lineHeight: "1.6vh" }}
              className={styles.header_name}
            >
              {t("Profit")}
            </span>
            <div className={styles.sort_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M1 4L4 1L7 4"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"0.8vh"}
                height={"0.5vh"}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  d="M7 1L4 4L1 1"
                  stroke="#9CA3AF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span
              style={
                row.original?.Profit < 0
                  ? { color: "#F63D68", fontWeight: "500" }
                  : { color: "#00B4A0", fontWeight: "500" }
              }
            >
              {row.original.Status === "CLOSED" ? (
                `${(
                  row.original?.Profit *
                  (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                ).toFixed(5)} ${account?.AccountCurrency == "EUR" ? "€" : "$"}`
              ) : (
                <span style={{ color: "#F63D68" }}>
                  {"-" +
                    (
                      row.original?.Price *
                      (account?.AccountCurrency == "EUR" ? eurToUsd : 1)
                    ).toFixed(5)}{" "}
                  {account?.AccountCurrency == "EUR" ? "€" : "$"}
                </span>
              )}
            </span>
          );
        },
      },
    ],
    [i18n.language, AssetsList]
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openOrderDrawer, setOpenOrderDrawer] = useState(false);
  const [activeOrder, setActiveOrder] = useState({});
  const Sell = async (row) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_test_url +
          `/api/account/assets/${row.AssetId}/close/deffered`,
        {
          user_id: account?.Id,
          asset_id: row.Id,
          eurToUsd: eurToUsd,
          usdToEur: usdToEur,
        },
        {
          headers: {
            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
          },
        }
      );
      if (data) {
        console.log("success");
      }
    } catch (e) {
      handleClick();
      setSnackMessage(e.response.data.message);
      setSnackType("error");
    }
  };

    const toggleDrawer = (newOpen, type) => () => {
        setOpenDrawer(newOpen);
        handleTabOrderChange(type)
    };
    const toggleOrderDrawer = (newOpen, element) => () => {
        setOpenOrderDrawer(newOpen);
        setActiveOrder(element)
    };
    const updateBackgroundPositionOrder = () => {
        let targetTab;
        switch (selectedOrderTab) {
            case 'buy':
                targetTab = buyOrder.current;
                break;
            case 'sell':
                targetTab = sellOrder.current;
                break;
            default:
                targetTab = buyOrder.current;
        }


        if (targetTab) {
            setBackgroundOrderStyle({
                width: targetTab.offsetWidth,
                height: targetTab.offsetHeight,
                transform: `translateX(${targetTab.offsetLeft - 3}px)`,
                transition: 'background-color 0.3s ease, transform 0.3s ease',
            });
        }
    };
    useEffect(() => {
        if (openDrawer) updateBackgroundPositionOrder();
    }, [openDrawer]);
    useEffect(() => {
        if (selectedOrderTab) updateBackgroundPositionOrder();
    }, [selectedOrderTab]);

    const [currentRecordsT, setCurrentRecordsT] = useState([]);
    const [currentRecordsTD, setCurrentRecordsTD] = useState([]);
    const [historyT, setHistoryT] = useState([]);
    useEffect(()=>{
        setCurrentRecordsT(currentRecords?.filter((val) => !val.Status.includes("D")))
        setCurrentRecordsTD(currentRecords?.filter((val) => val.Status.includes("D") && val.Status != "SIMULATED"))
        setHistoryT(history)
    },[AssetsList,currentRecords,history])

    useEffect(() => {
        if (currentRecordsT.length > 0) {
            let temp = currentRecordsT.reduce((sum, currentValue) => 
                sum + (currentValue.Status === 'BUY'
                    ? +parseFloat(currentValue?.Profit)?.toFixed(1)
                    : +parseFloat(currentValue?.Profit)?.toFixed(1)
                ), 0);
            setProfit(temp);
        }
    }, [currentRecordsT]);
    return (
        <div className={styles.main}>
            <div className={styles.main_block} style={{flex: isOrdersBig? 0.38: 3.4}}>
                <div className={styles.block_title}>
                    <div className={styles.leftside}>
                        <div className={styles.active_name}>
                        {assetsLogo[activeAsset?.Ticker] ? (
  <div
    dangerouslySetInnerHTML={{ __html: assetsLogo[activeAsset?.Ticker] }}
    style={{ width: '32px', height: '32px', overflow: 'hidden' }}
  />
) : (
  <img
    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${activeAsset?.Subtitle.slice(
      1,
      4
    ).toLowerCase()}.svg`}
    alt="icon"
    onError={handleImgError}
    style={{ width: '32px', height: '32px', borderRadius: '50%' }}
  />
)}
                            <div className={styles.name}>
                                <span className={styles.subtitle}>
                                {account?.AccountCurrency == "EUR"?activeAsset?.Subtitle.slice(1,-1).replace("/USD","/EUR"):activeAsset?.Subtitle.slice(1,-1)}
                                </span>
                                <span className={styles.title}>
                                    {activeAsset?.Title}
                                </span>
                            </div>
                        </div>
                        <div className={styles.active_values}>
                            <div className={styles.price}>
                                {parseFloat(secureLocalStorage.getItem('selected_account')?.AccountCurrency === 'EUR'?(activePrice * eurToUsd):activePrice).toFixed(5)}
                                <span>{t('Price')}</span>
                            </div>
                            <div style={{color: !activeAsset?.Tendency.includes('-')?'#00B4A0':'#F63D68'}} className={styles.day_changes}>
                                {activeAsset?.Tendency}
                                <span>{t('Change 24h')}</span>
                            </div>
                            <div className={styles.day_high}>
                                {parseFloat(secureLocalStorage.getItem('selected_account')?.AccountCurrency === 'EUR'? (activeAsset?.High * eurToUsd):activeAsset?.High).toFixed(5)}
                                <span>{t('24h high')}</span>
                            </div>
                            <div className={styles.day_low}>
                            {parseFloat(secureLocalStorage.getItem('selected_account')?.AccountCurrency === 'EUR'? (activeAsset?.Low * eurToUsd):activeAsset?.Low).toFixed(5)}
                                <span>{t('24h low')}</span>
                            </div>
                        </div>
                    </div>  
                    <div className={styles.rightside}>
                        {/* <div className={styles.tabs}>
                            <div className={styles.background} style={backgroundIntervalStyle} />
                            <button ref={min} className={`${styles.tab} ${selectedIntervalTab === '1m' ? styles.active : ''}`} onClick={() => { handleIntervalChange(60, '1m'); handleTabIntervalChange('1m') }}>1m</button>
                            <button ref={fivemin} className={`${styles.tab} ${selectedIntervalTab === '5m' ? styles.active : ''}`} onClick={() => { handleIntervalChange(5 * 60, '5m'); handleTabIntervalChange('5m') }}>5m</button>
                            <button ref={fifteenmin} className={`${styles.tab} ${selectedIntervalTab === '15m' ? styles.active : ''}`} onClick={() => { handleIntervalChange(15 * 60, '15m'); handleTabIntervalChange('15m') }}>15m</button>
                            <button ref={thirtymin} className={`${styles.tab} ${selectedIntervalTab === '30m' ? styles.active : ''}`} onClick={() => { handleIntervalChange(30 * 60, '30m'); handleTabIntervalChange('30m') }}>30m</button>
                            <button ref={hour} className={`${styles.tab} ${selectedIntervalTab === '1h' ? styles.active : ''}`} onClick={() => { handleIntervalChange(60 * 60, '1h'); handleTabIntervalChange('1h') }}>1h</button>
                            <button ref={fourhour} className={`${styles.tab} ${selectedIntervalTab === '4h' ? styles.active : ''}`} onClick={() => { handleIntervalChange(240 * 60, '4h'); handleTabIntervalChange('4h') }}>4h</button>
                            <button ref={day} className={`${styles.tab} ${selectedIntervalTab === '1d' ? styles.active : ''}`} onClick={() => { handleIntervalChange(1440 * 60, '1d'); handleTabIntervalChange('1d') }}>1d</button>
                            {activeAsset?.Type !== 'ruShare' && (<button ref={week} className={`${styles.tab} ${selectedIntervalTab === '1w' ? styles.active : ''}`} onClick={() => { handleIntervalChange(10080 * 60, '1w'); handleTabIntervalChange('1w') }}>1w</button>)}
                            {activeAsset?.Type !== 'ruShare' && (<button ref={month} className={`${styles.tab} ${selectedIntervalTab === '1M' ? styles.active : ''}`} onClick={() => { handleIntervalChange(40320 * 60, '1mo'); handleTabIntervalChange('1M') }}>1M</button>)}
                        </div> */}
                        <div className={styles.buy_sell}>
                        <button onClick={toggleDrawer(true, 'sell')}>
                            <span className={styles.sell_gradient}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={'13.9vh'} height={'4vh'} viewBox="0 0 132 40" fill="none">
                                    <g filter="url(#filter0_f_604_2437)">
                                        <circle cx={16} cy={16} r={16} fill="#F63D68" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_604_2437" x={-100} y={-100} width={232} height={232} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation={50} result="effect1_foregroundBlur_604_2437" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.00033 1.3335C4.31843 1.3335 1.33366 4.31826 1.33366 8.00016C1.33366 11.6821 4.31843 14.6668 8.00033 14.6668C11.6822 14.6668 14.667 11.6821 14.667 8.00016C14.667 4.31826 11.6822 1.3335 8.00033 1.3335ZM9.50033 10.0002C9.50033 10.2763 9.27647 10.5002 9.00033 10.5002H6.00033C5.72418 10.5002 5.50033 10.2763 5.50033 10.0002V7.00016C5.50033 6.72402 5.72418 6.50016 6.00033 6.50016C6.27647 6.50016 6.50033 6.72402 6.50033 7.00016V8.79306L9.64677 5.64661C9.84203 5.45135 10.1586 5.45135 10.3539 5.64661C10.5491 5.84187 10.5491 6.15845 10.3539 6.35372L7.20743 9.50016H9.00033C9.27647 9.50016 9.50033 9.72402 9.50033 10.0002Z" fill="#F63D68" />
                            </svg>
                            <span>{t('Sell')}</span>
                        </button>
                        <button onClick={toggleDrawer(true, 'buy')}>
                            <span>{t('Buy')}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668ZM6.49967 6.00016C6.49967 5.72402 6.72353 5.50016 6.99967 5.50016H9.99967C10.2758 5.50016 10.4997 5.72402 10.4997 6.00016V9.00016C10.4997 9.27631 10.2758 9.50016 9.99967 9.50016C9.72353 9.50016 9.49967 9.27631 9.49967 9.00016V7.20727L6.35323 10.3537C6.15797 10.549 5.84138 10.549 5.64612 10.3537C5.45086 10.1585 5.45086 9.84187 5.64612 9.64661L8.79257 6.50016H6.99967C6.72353 6.50016 6.49967 6.27631 6.49967 6.00016Z" fill="#00B4A0" />
                            </svg>
                            <span className={styles.buy_gradient}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={'13.9vh'} height={'4vh'} viewBox="0 0 132 40" fill="none">
                                    <g filter="url(#filter0_f_604_2442)">
                                        <circle cx={116} cy={20} r={16} fill="#00B4A0" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_604_2442" x={0} y={-96} width={232} height={232} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation={50} result="effect1_foregroundBlur_604_2442" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                        </button>
                    </div>
                        <div className={styles.favorites} onClick={() => toggleFavorite(activeAsset?.Id)}>
                            {
                                favorites?.includes(activeAsset?.Id) ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width={'2vh'} height={'2vh'} viewBox="0 0 20 20" fill="none">
                                        <path d="M7.62796 4.50682C8.6835 2.61328 9.21127 1.6665 10.0003 1.6665C10.7894 1.6665 11.3171 2.61327 12.3727 4.50682L12.6458 4.9967C12.9457 5.53478 13.0957 5.80383 13.3295 5.98134C13.5634 6.15886 13.8546 6.22475 14.4371 6.35654L14.9674 6.47653C17.0171 6.9403 18.042 7.17219 18.2858 7.95628C18.5296 8.74037 17.831 9.5574 16.4336 11.1914L16.0721 11.6142C15.675 12.0785 15.4764 12.3107 15.3871 12.5979C15.2978 12.8852 15.3278 13.1949 15.3878 13.8145L15.4425 14.3785C15.6538 16.5587 15.7594 17.6488 15.121 18.1334C14.4827 18.6179 13.5231 18.1761 11.6039 17.2925L11.1074 17.0639C10.5621 16.8128 10.2894 16.6872 10.0003 16.6872C9.71128 16.6872 9.4386 16.8128 8.89323 17.0639L8.39672 17.2925C6.47755 18.1761 5.51797 18.6179 4.87962 18.1334C4.24126 17.6488 4.34689 16.5587 4.55816 14.3785L4.61281 13.8145C4.67285 13.1949 4.70286 12.8852 4.61354 12.5979C4.52423 12.3107 4.32568 12.0785 3.92859 11.6142L3.56707 11.1914C2.1697 9.5574 1.47101 8.74037 1.71484 7.95628C1.95867 7.17219 2.98354 6.9403 5.03327 6.47653L5.56356 6.35654C6.14603 6.22476 6.43727 6.15886 6.67111 5.98134C6.90495 5.80383 7.05493 5.53478 7.35488 4.9967L7.62796 4.50682Z" fill="#E9B90E" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width={'2vh'} height={'2vh'} viewBox="0 0 20 20" fill="none">
                                        <path d="M7.62796 4.50682C8.6835 2.61328 9.21127 1.6665 10.0003 1.6665C10.7894 1.6665 11.3171 2.61327 12.3727 4.50682L12.6458 4.9967C12.9457 5.53478 13.0957 5.80383 13.3295 5.98134C13.5634 6.15886 13.8546 6.22475 14.4371 6.35654L14.9674 6.47653C17.0171 6.9403 18.042 7.17219 18.2858 7.95628C18.5296 8.74037 17.831 9.5574 16.4336 11.1914L16.0721 11.6142C15.675 12.0785 15.4764 12.3107 15.3871 12.5979C15.2978 12.8852 15.3278 13.1949 15.3878 13.8145L15.4425 14.3785C15.6538 16.5587 15.7594 17.6488 15.121 18.1334C14.4827 18.6179 13.5231 18.1761 11.6039 17.2925L11.1074 17.0639C10.5621 16.8128 10.2894 16.6872 10.0003 16.6872C9.71128 16.6872 9.4386 16.8128 8.89323 17.0639L8.39672 17.2925C6.47755 18.1761 5.51797 18.6179 4.87962 18.1334C4.24126 17.6488 4.34689 16.5587 4.55816 14.3785L4.61281 13.8145C4.67285 13.1949 4.70286 12.8852 4.61354 12.5979C4.52423 12.3107 4.32568 12.0785 3.92859 11.6142L3.56707 11.1914C2.1697 9.5574 1.47101 8.74037 1.71484 7.95628C1.95867 7.17219 2.98354 6.9403 5.03327 6.47653L5.56356 6.35654C6.14603 6.22476 6.43727 6.15886 6.67111 5.98134C6.90495 5.80383 7.05493 5.53478 7.35488 4.9967L7.62796 4.50682Z" stroke={theme === 'dark' ? "#FCFCFC" : '#1F2937'} />
                                    </svg>
                            }
                        </div>
                    </div>
                    <div className={styles.trading_orders}>
                        <div className={styles.background} style={backgroundTradingStyle} />
                        <button ref={trading} className={`${styles.tab} ${selectedTradingTab === 'trading' ? styles.active : ''}`} onClick={() => { handleTabTradingChange('trading') }}>{t('Trading')}</button>
                        <button ref={orders} className={`${styles.tab} ${selectedTradingTab === 'orders' ? styles.active : ''}`} onClick={() => { handleTabTradingChange('orders') }}>{t('Orders')}</button>
                    </div>
                    {selectedTradingTab === 'trading' && (
                        <div className={styles.mobile_assets} onClick={toggleAssetsDrawer(true)}>
                            <div className={styles.left}>
                            {assetsLogo[activeAsset?.Ticker] ? (
  <div
    dangerouslySetInnerHTML={{ __html: assetsLogo[activeAsset?.Ticker] }}
    style={{ width: '32px', height: '32px', borderRadius: '50%', overflow: 'hidden' }}
  />
) : (
  <img
    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${activeAsset?.Subtitle.slice(
      1,
      4
    ).toLowerCase()}.svg`}
    alt="icon"
    onError={handleImgError}
    style={{ width: '32px', height: '32px', borderRadius: '50%' }}
  />
)}
                                <div className={styles.title_price}>
                                    <span className={styles.title}>{activeAsset?.Subtitle?
                                    account?.AccountCurrency == "EUR"
                                    ?
                                    activeAsset?.Subtitle?.slice(1,-1).replace("/USD","/EUR")
                                    :
                                    activeAsset?.Subtitle?.slice(1,-1)
                                    :
                                    account?.AccountCurrency == "EUR"
                                    ?
                                    activeAsset?.SubTitle?.slice(1,-1).replace("/USD","/EUR")
                                    :
                                    activeAsset?.SubTitle?.slice(1,-1)
                                    }</span>
                                    <span className={styles.price}>{activePrice?.toFixed(activePrice > 1 ? 2 : 4)}</span>
                                </div>
                            </div>
                            <div className={styles.icon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.6666 6L7.99992 10L3.33325 6" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>

                    )}
                    
                    {selectedTradingTab === 'trading' && (
                        <div className={styles.buy_sell_mobile}>
                        <button onClick={toggleDrawer(true, 'sell')}>
                            <span className={styles.sell_gradient}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={'13.9vh'} height={'4vh'} viewBox="0 0 132 40" fill="none">
                                    <g filter="url(#filter0_f_604_2437)">
                                        <circle cx={16} cy={16} r={16} fill="#F63D68" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_604_2437" x={-100} y={-100} width={232} height={232} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation={50} result="effect1_foregroundBlur_604_2437" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.00033 1.3335C4.31843 1.3335 1.33366 4.31826 1.33366 8.00016C1.33366 11.6821 4.31843 14.6668 8.00033 14.6668C11.6822 14.6668 14.667 11.6821 14.667 8.00016C14.667 4.31826 11.6822 1.3335 8.00033 1.3335ZM9.50033 10.0002C9.50033 10.2763 9.27647 10.5002 9.00033 10.5002H6.00033C5.72418 10.5002 5.50033 10.2763 5.50033 10.0002V7.00016C5.50033 6.72402 5.72418 6.50016 6.00033 6.50016C6.27647 6.50016 6.50033 6.72402 6.50033 7.00016V8.79306L9.64677 5.64661C9.84203 5.45135 10.1586 5.45135 10.3539 5.64661C10.5491 5.84187 10.5491 6.15845 10.3539 6.35372L7.20743 9.50016H9.00033C9.27647 9.50016 9.50033 9.72402 9.50033 10.0002Z" fill="#F63D68" />
                            </svg>
                            <span>{t('Sell')}</span>
                        </button>
                        <button onClick={toggleDrawer(true, 'buy')}>
                            <span>{t('Buy')}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668ZM6.49967 6.00016C6.49967 5.72402 6.72353 5.50016 6.99967 5.50016H9.99967C10.2758 5.50016 10.4997 5.72402 10.4997 6.00016V9.00016C10.4997 9.27631 10.2758 9.50016 9.99967 9.50016C9.72353 9.50016 9.49967 9.27631 9.49967 9.00016V7.20727L6.35323 10.3537C6.15797 10.549 5.84138 10.549 5.64612 10.3537C5.45086 10.1585 5.45086 9.84187 5.64612 9.64661L8.79257 6.50016H6.99967C6.72353 6.50016 6.49967 6.27631 6.49967 6.00016Z" fill="#00B4A0" />
                            </svg>
                            <span className={styles.buy_gradient}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={'13.9vh'} height={'4vh'} viewBox="0 0 132 40" fill="none">
                                    <g filter="url(#filter0_f_604_2442)">
                                        <circle cx={116} cy={20} r={16} fill="#00B4A0" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_604_2442" x={0} y={-96} width={232} height={232} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation={50} result="effect1_foregroundBlur_604_2442" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                        </button>
                        </div>
                        
                    )}
                </div>
                {
                    selectedTradingTab === 'orders' ?
                        <div className={styles.mobile_orders}>
                            <div className={styles.search} onClick={handleSearchClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                                    <g clipPath="url(#clip0_436_4317)">
                                        <circle cx="7.66634" cy="7.66683" r="6.33333" stroke="#51525C" />
                                        <path d="M13.333 13.3335L14.6663 14.6668" stroke="#51525C" strokeLinecap="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_436_4317">
                                            <rect width={16} height={16} fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <input ref={inputRef} placeholder={t('Search')} />
                            </div>
                            <div className={styles.mobile_orders_tabs}>
                                <div className={styles.background} style={backgroundMobileStyle} />
                                <button
                                    ref={openMobile}
                                    className={`${styles.tab} ${selectedMobileTab === 'open' ? styles.active : ''}`}
                                    onClick={() => handleTabMobileChange('open')}
                                >
                                    {t("Open Orders")}
                                </button>
                                <button
                                    ref={deferredMobile}
                                    className={`${styles.tab} ${selectedMobileTab === 'deferred' ? styles.active : ''}`}
                                    onClick={() => handleTabMobileChange('deferred')}
                                >
                                    {t("Deferred Orders")}
                                </button>
                                <button
                                    ref={closeMobile}
                                    className={`${styles.tab} ${selectedMobileTab === 'close' ? styles.active : ''}`}
                                    onClick={() => handleTabMobileChange('close')}
                                >
                                    {t("History")}
                                </button>
                            </div>
                            {
                                selectedMobileTab === 'close' && (
                                    <div className={styles.transactions_list}>
                                        {historyT.map(el =>
                                            <div onClick={toggleOrderDrawer(true, el)} className={styles.transaction} key={el.Id}>
                                                <div className={styles.type_status}>
                                                    <div className={`${styles.left_side}`}>
                                                    {assetsLogo[AssetsList?.find(val=>val.Title === el.Asset)?.Ticker] ? (
  <div
    dangerouslySetInnerHTML={{ __html: assetsLogo[AssetsList?.find(val=>val.Title === el.Asset)?.Ticker] }}
    style={{
      width: '32px',
      height: '32px',
      overflow: 'hidden',
    }}
  />
) : (
  <img
    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${el.SubTitle.slice(
      1,
      4
    ).toLowerCase()}.svg`}
    alt="icon"
    onError={handleImgError}
    style={{
      width: '32px',
      height: '32px',
      borderRadius: '50%',
    }}
  />
)}
                    {account?.AccountCurrency == "EUR"?el.SubTitle.slice(1,-1).replace("/USD","/EUR"):el.SubTitle.slice(1,-1)}
                                                        <span className={`${styles.type} ${el.OrderType?.includes('BUY') ? styles.buy : el.OrderType?.includes('SELL') ? styles.sell : styles.simulate}`}>{el.OrderType}</span>
                                                        <span className={styles.leverage}>x{el.Leverage}</span>
                                                    </div>
                                                    <div className={styles.right_side}>
                                                        <span className={`${styles.type} ${styles.simulate}`}>
                                                            {el.Status?.slice(0, 1) + el.Status?.slice(1).toLowerCase()}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className={styles.account_amount}>
                                                    <div className={styles.account}>
                                                        <label htmlFor="">{t('Profit')}</label>
                                                        <p style={{ color: parseFloat(el.Profit) > 0 ? '#00B4A0' : "#F63D68" }}>
                                                            {el.Profit > 0 ? '+' : ''}
                                                            {parseFloat(`${el.Profit}`.slice(1)) > 1 ? ' ' + (el.Profit < 0 ? '- ' + parseFloat(`${el.Profit}`.slice(1)).toFixed(2) : el.Profit?.toFixed(2)) : ' ' + (el.Profit < 0 ? '- ' + parseFloat(`${el.Profit}`.slice(1)).toFixed(5) : el.Profit?.toFixed(5))}
                                                            {
                                                                account?.AccountCurrency == "EUR"
                                                                    ?
                                                                    " €"
                                                                    :
                                                                    " $"
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className={styles.account} style={{ alignItems: 'end' }}>
                                                        <label htmlFor="">{t('Date')}</label>
                                                        <p>
                                                            {moment(el.Date).format("YYYY-MM-DD HH:mm")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                )
                            }
                            
                            {
                                selectedMobileTab === 'deferred' && (
                                    <div className={styles.transactions_list}>
                                        {currentRecordsTD?.map(el =>
                                            <div className={styles.transaction} key={el.Id}>
                                                <div className={styles.type_status} onClick={toggleOrderDrawer(true, el)} >
                                                    <div className={`${styles.left_side}`}>
                                                    {assetsLogo[AssetsList?.find(val=>val.Title === el.Title)?.Ticker] ? (
  <div
    dangerouslySetInnerHTML={{ __html: assetsLogo[AssetsList?.find(val=>val.Title === el.Title)?.Ticker] }}
    style={{
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      overflow: 'hidden',
    }}
  />
) : (
  <img
    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${el.Subtitle.slice(
      1,
      4
    ).toLowerCase()}.svg`}
    alt="icon"
    onError={handleImgError}
    style={{
      width: '32px',
      height: '32px',
      borderRadius: '50%',
    }}
  />
)}
                    {account?.AccountCurrency == "EUR"?el.Subtitle.slice(1,-1).replace("/USD","/EUR"):el.Subtitle.slice(1,-1)}
                                                    </div>
                                                    <div className={styles.right_side}>
                                                        <span className={styles.leverage}>x{el.Leverage}</span>
                                                        <span className={`${styles.type} ${el.Status?.includes('BUY') ? styles.buy : el.Status?.includes('SELL') ? styles.sell : styles.simulate}`}>
                                                            {el.Status?.includes('D') ? (el.Status?.slice(0, 1) + el.Status?.slice(1).toLowerCase()).slice(0, -1) : (el.Status?.slice(0, 1) + el.Status?.slice(1).toLowerCase())}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className={styles.account_amount}>
                                                    <div className={styles.account} onClick={toggleOrderDrawer(true, el)} >
                                                        <label htmlFor="">{t('TP/SL')}</label>
                                                        <p className={styles.profit_loss}>
                                                            <span className={styles.take_profit}>
                                                                {
                                                                    account?.AccountCurrency == "EUR"
                                                                        ?
                                                                        "€ "
                                                                        :
                                                                        "$ "
                                                                }
                                                                {el.TakeProfit ? parseFloat(el.TakeProfit) > 1 ? el.TakeProfit?.toFixed(2) : el.TakeProfit?.toFixed(5) : ' 0'}
                                                            </span>
                                                            /
                                                            <span className={styles.stop_loss}>
                                                                {
                                                                    account?.AccountCurrency == "EUR"
                                                                        ?
                                                                        "€ "
                                                                        :
                                                                        "$ "
                                                                }
                                                                {el.StopLoss ? parseFloat(el.StopLoss) > 1 ? el.TakeProfit?.toFixed(2) : el.StopLoss?.toFixed(5) : ' 0'}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className={styles.account} style={{ alignItems: 'end' }}>
                                                    <button onClick={()=>{Sell(el)}} style={el.Status === 'BUY' ?
                                                        parseFloat(el.Profit) > 0 ?
                                                        {cursor:'pointer',background:'none', border:'1px solid #F63D68' , color:'#F63D68', padding:'8px 12px' , borderRadius:'4px' }
                                                        :{cursor:'pointer',background:'none', border:'1px solid #00B4A0' , color:'#00B4A0', padding:'8px 12px' , borderRadius:'4px'}
                                                        :parseFloat(el.Profit) * -1 > 0 ?
                                                        {cursor:'pointer',background:'none', border:'1px solid #F63D68' , color:'#F63D68', padding:'8px 12px' , borderRadius:'4px' }
                                                        :{cursor:'pointer',background:'none', border:'1px solid #00B4A0' , color:'#00B4A0', padding:'8px 12px' , borderRadius:'4px'}  }>
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                )

                            }
                            {
                                selectedMobileTab === 'open' && (
                                    <div className={styles.transactions_list}>
                                        {currentRecordsT?.map(el =>
                                            <div className={styles.transaction} key={el.Id}>
                                                <div className={styles.type_status} onClick={toggleOrderDrawer(true, el)}>
                                                    <div className={`${styles.left_side}`}>
                                                    {assetsLogo[AssetsList?.find(val=>val.Title === el.Title)?.Ticker]  ? (
  <div
    dangerouslySetInnerHTML={{ __html: assetsLogo[AssetsList?.find(val=>val.Title === el.Title)?.Ticker]  }}
    style={{
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      overflow: 'hidden',
    }}
  />
) : (
  <img
    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${el.Subtitle.slice(
      1,
      4
    ).toLowerCase()}.svg`}
    alt="icon"
    onError={handleImgError}
    style={{
      width: '32px',
      height: '32px',
      borderRadius: '50%',
    }}
  />
)}
                    {account?.AccountCurrency == "EUR"?el.Subtitle.slice(1,-1).replace("/USD","/EUR"):el.Subtitle.slice(1,-1)}
                                                    </div>
                                                    <div className={styles.right_side}>
                                                        <span className={`${styles.type} ${el.Status?.includes('BUY') ? styles.buy : ''}`}>{el.Status}</span>
                                                        <span className={styles.leverage}>x{el.Leverage}</span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className={styles.account_amount}>
                                                    <div className={styles.account} onClick={toggleOrderDrawer(true, el)}>
                                                        <label htmlFor="">{t('Amount')}</label>
                                                        <p>
                                                            {
                                                                account?.AccountCurrency == "EUR"
                                                                    ?
                                                                    "€"
                                                                    :
                                                                    "$"
                                                            }{el.Price}</p>
                                                    </div>
                                                    <div className={styles.account} style={{ alignItems: 'end' }}>
                                                    <button onClick={()=>{Close(el)}} style={el.Status === 'BUY' ?
                          parseFloat(el.Profit) > 0 ?
                          {cursor:'pointer',background:'none', border:'1px solid #F63D68' , color:'#F63D68', padding:'8px 12px' , borderRadius:'4px' }
                          :{cursor:'pointer',background:'none', border:'1px solid #00B4A0' , color:'#00B4A0', padding:'8px 12px' , borderRadius:'4px'}
                          :parseFloat(el.Profit) * -1 > 0 ?
                          {cursor:'pointer',background:'none', border:'1px solid #F63D68' , color:'#F63D68', padding:'8px 12px' , borderRadius:'4px' }
                          :{cursor:'pointer',background:'none', border:'1px solid #00B4A0' , color:'#00B4A0', padding:'8px 12px' , borderRadius:'4px'}  }>
                            Close
                          </button>
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                )
                            }
                        </div>
                        : null
                }
                {!isOrdersBig && <div className={styles.block_graph} id="graph_container" style={(selectedTradingTab === 'orders' && window.innerWidth < 600)? { display: 'none' } : {}}>
                    <Graph active={activeAsset?.Title} interval={interval} intervalLabel={intervalLabel} activeType={activeAsset?.Type} usdToEur={usdToEur} eurToUsd={eurToUsd} theme={theme} ticker = {activeAsset?.Ticker}/>
                </div>}
            </div>
            <div className={styles.orders_block} style={{flex: isOrdersBig? 4: 1}}>
                <div className={styles.orders_content}>
                    <div className={styles.orders_top}>
                        <div className={styles.tabs}>
                            <div className={styles.background} style={backgroundTableStyle} />
                            <button
                                ref={open}
                                className={`${styles.tab} ${selectedTab === 'open' ? styles.active : ''}`}
                                onClick={() => handleTabTableChange('open')}
                            >
                                {t("Open Orders")}
                            </button>
                            <button
                                ref={deferred}
                                className={`${styles.tab} ${selectedTab === 'deferred' ? styles.active : ''}`}
                                onClick={() => handleTabTableChange('deferred')}
                            >
                                {t("Deferred Orders")}
                            </button>
                            <button
                                ref={close}
                                className={`${styles.tab} ${selectedTab === 'close' ? styles.active : ''}`}
                                onClick={() => handleTabTableChange('close')}
                            >
                                {t("History")}
                            </button>
                        </div>
                        <button onClick={()=> setIsOrdersBig(!isOrdersBig)} className={`${styles.change_size} ${!isOrdersBig? styles.rotate :''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.6vh" height="1.6vh" viewBox="0 0 16 16" fill="none">
                                <path d="M12.6668 6L8.00016 10L3.3335 6" stroke="var(--font-mine)" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </div>
                    <div className={styles.table} style={{ width: '100%' }}>
                        {
                            selectedTab == "open" &&
                            <OrdersTable
                            key={AssetsList.length}
                            data={currentRecordsT}
                            columns={columns}
                            theme={theme}
                            height={isOrdersBig? true: null}
                            />
                        }
                        {
                            selectedTab == "deferred" &&
                            <OrdersTable
                            key={AssetsList.length}
                            data={currentRecordsTD}
                            columns={columnsDeferred}
                            theme={theme}
                            height={isOrdersBig? true: null}
                            />
                        }
                        {
                            selectedTab == "close" &&
                            <OrdersTableHistory
                            key={AssetsList.length}
                            data={historyT}
                            columns={columnsClosed}
                            theme={theme}
                            height={isOrdersBig? true: null}
                            />
                        }
                    </div>
                </div>
            </div>
            <EditOrderDrawer
                currentRecords={currentRecords}
                currentRow={currentRow}
                active={activeAsset}
                activePrice={activePrice}
                eurToUsd={eurToUsd}
                usdToEur={usdToEur}
                open={openEditDrawer}
                theme={theme}
                toggleDrawer={toggleEditDrawer}
                buyOrder={buyOrder}
                sellOrder={sellOrder}
                backgroundOrderStyle={backgroundOrderStyle}
                handleTabOrderChange={handleTabOrderChange}
                selectedOrderTab={selectedOrderTab}
                account = {account}
                setSnackMessage={setSnackMessage}
                setSnackType={setSnackType}
                handleClick={handleClick}
            />
            <BuySellDrawer
                applications={applications}
                active={activeAsset}
                activePrice={activePrice}
                eurToUsd={eurToUsd}
                usdToEur={usdToEur}
                open={openDrawer}
                theme={theme}
                toggleDrawer={toggleDrawer}
                buyOrder={buyOrder}
                sellOrder={sellOrder}
                backgroundOrderStyle={backgroundOrderStyle}
                handleTabOrderChange={handleTabOrderChange}
                selectedOrderTab={selectedOrderTab}
                account = {account}
                setSnackMessage={setSnackMessage}
                setSnackType={setSnackType}
                handleClick={handleClick}
            />
            <MobileAssetsDrawer
                open={openAssetsDrawer}
                toggleDrawer={toggleAssetsDrawer}
                theme={theme}
                currentRecords={currentRecords}
                favorites={favorites}
                toggleFavorite={toggleFavorite}
                setActiveAsset={setActiveAsset}
                setActivePrice={setActivePrice}
                activeAsset={activeAsset}
                account = {account}
                eurToUsd={eurToUsd}
                setSnackMessage={setSnackMessage}
                setSnackType={setSnackType}
                handleClick={handleClick}
            />
            <MobileOrderDrawer account={account} currentRecords = {currentRecords} theme={theme} open={openOrderDrawer} eurToUsd={eurToUsd} toggleDrawer={toggleOrderDrawer} activeOrder={activeOrder} marketPrice={activeOrder?.MarketPrice} AssetsList={AssetsList}/>
        </div>
    )
}
