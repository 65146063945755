import React, { useEffect, useRef, useMemo, useState } from 'react';
import './index.css';
import { widget } from '../../charting_library';
import CustomDatafeed from '../CustomDatafeed/CustomDatafeed';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

function getLanguageFromURL() {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getTheme(theme) {
    return theme === "dark" ? "Dark" : "Light";
}

export const TVChartContainer = ({ theme,ticker }) => {
    const chartContainerRef = useRef();
    const tvWidgetRef = useRef(null);
    const datafeedRef = useRef(null);
    const [widgetT, setWidgetT] = useState(null);
    const { t, i18n } = useTranslation();

    // Состояние для хранения размера шрифта
    // const [fontSize, setFontSize] = useState(null);
    // Состояние для отслеживания готовности виджета
    const [isWidgetReady, setIsWidgetReady] = useState(false);

    // Функция для получения размера шрифта
    const determineFontSize = () => {
        return window.innerWidth < 768 ? 10 : 14; // Настройте значения по необходимости
    };

    // Установка размера шрифта при монтировании компонента
    useEffect(() => {
        const initialFontSize = determineFontSize();
        // setFontSize(initialFontSize);
    }, []);
    const defaultProps = useMemo(() => ({
        symbol: ticker?ticker:"BTCUSDT",
        interval: '1D',
        datafeedUrl: 'https://trade.diamondtrade.pro/api',
        libraryPath: 'charting_library/',
        chartsStorageUrl: 'https://saveload.tradingview.com',
        chartsStorageApiVersion: '1.1',
        clientId: 'tradingview.com',
        userId: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {},
        custom_css_url: './index.css',
    }), [ticker]);
    const clientTimeZone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);
    // Инициализация виджета после установки размера шрифта
    useEffect(() => {
        // if (fontSize === null) return; // Ждем, пока размер шрифта не будет определен

        const symbol = defaultProps.symbol;
        const socketUrl = `wss://trade.diamondtrade.pro/ws/data/`;
        const datafeed = new CustomDatafeed(defaultProps.datafeedUrl, socketUrl, defaultProps);
        datafeedRef.current = datafeed;

        const widgetOptions = {
            symbol: defaultProps.symbol,
            datafeed: datafeed,
            interval: defaultProps.interval,
            container: chartContainerRef.current,
            library_path: defaultProps.libraryPath,
            timezone: clientTimeZone == "Europe/Kiev"?"Europe/Riga":clientTimeZone,
            locale: getLanguageFromURL() || 'en',
            disabled_features: ['use_localstorage_for_settings', "header_saveload", "header_screenshot", "header_settings","study_templates","header_compare","symbol_search_hot_key","header_symbol_search"],
            enabled_features: ["iframe_loading_compatibility_mode"],
            charts_storage_url: defaultProps.chartsStorageUrl,
            charts_storage_api_version: defaultProps.chartsStorageApiVersion,
            client_id: defaultProps.clientId,
            user_id: defaultProps.userId,
            fullscreen: defaultProps.fullscreen,
            autosize: defaultProps.autosize,
            studies_overrides: defaultProps.studiesOverrides,
            theme: getTheme(theme),
            custom_css_url: defaultProps.custom_css_url,
            overrides: {
                // Настройки тулбара
                "toolbarProperties.backgroundColor": "#18181B",
                "toolbarProperties.drawingsColor": "#AAA",
                "toolbarProperties.textColor": "#AAA",
                // Настройки свечей и других элементов графика
                "mainSeriesProperties.candleStyle.upColor": "#00B4A0",
                "mainSeriesProperties.candleStyle.downColor": "#F63D68",
                "paneProperties.vertGridProperties.color": "rgba(0, 0, 0, 0)",
                "paneProperties.horzGridProperties.color": "#444",
                "scalesProperties.textColor": "#AAA",
                // "scalesProperties.fontSize": fontSize,
                // "timeAxisProperties.fontSize": fontSize,
                "symbolWatermarkProperties.color": "rgba(0, 0, 0, 0)",
                "mainSeriesProperties.candleStyle.borderUpColor": "#00B4A0",
                "mainSeriesProperties.candleStyle.borderDownColor": "#F63D68",
                "mainSeriesProperties.candleStyle.wickUpColor": "#00B4A0",
                "mainSeriesProperties.candleStyle.wickDownColor": "#F63D68",
            },
            custom_formatters: {
                timeFormatter: {
                    format: (date) => {
                        const _format_str = '%h:%m';
                        return _format_str
                            .replace('%h', date.getUTCHours().toString().padStart(2, '0'))
                            .replace('%m', date.getUTCMinutes().toString().padStart(2, '0'))
                            .replace('%s', date.getUTCSeconds().toString().padStart(2, '0'));
                    }
                },
                dateFormatter: {
                    format: (date) => {
                        return `${date.getUTCFullYear()}/${(date.getUTCMonth() + 1)
                            .toString()
                            .padStart(2, '0')}/${date.getUTCDate().toString().padStart(2, '0')}`;
                    }
                }
            }
        };

        const tvWidget = new widget(widgetOptions);
        tvWidgetRef.current = tvWidget;
        tvWidget.onChartReady(() => {
            setWidgetT(tvWidget);
            setIsWidgetReady(true); // Устанавливаем готовность виджета
        });

        return () => {
            if (tvWidgetRef.current) {
                tvWidgetRef.current.remove();
                tvWidgetRef.current = null;
                setWidgetT(null);
                console.log('Виджет TradingView удалён.');
            }
            if (datafeedRef.current) {
                datafeedRef.current.close();
                datafeedRef.current = null;
                console.log('Datafeed закрыт.');
            }
        };
    }, [defaultProps]);

    // Обработка изменения темы
    useEffect(() => {
        try {
            if (tvWidgetRef !== null && tvWidgetRef.current) {
                // Применяем тему при изменении
                tvWidgetRef?.current?.changeTheme(getTheme(theme));
            }
        } catch (error) {
            // console.log(error);
        }
    }, [theme]);

    // Обработка изменения размера окна с использованием debounce
    // useEffect(() => {
    //     // if (fontSize === null) return; // Ждем, пока размер шрифта не будет определен

    //     const handleResize = debounce(() => {
    //         if (tvWidgetRef.current) {
    //             const newFontSize = determineFontSize();
    //             // setFontSize(newFontSize); // Обновляем состояние размера шрифта
    //             tvWidgetRef.current.chart().applyOverrides({
    //                 "scalesProperties.fontSize": newFontSize,
    //                 "timeAxisProperties.fontSize": newFontSize,
    //             });
    //         }
    //     }, 100); // Задержка 300 мс

    //     window.addEventListener('resize', handleResize);

    //     // Очистка обработчика при размонтировании компонента
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //         handleResize.cancel();
    //     };
    // }, [fontSize]);

    return (
        // Скрываем контейнер до полной инициализации виджета
        <div
            ref={chartContainerRef}
            className={'TVChartContainer'}
            style={{
                height: '100%',
                width: '100%',
                // visibility: isWidgetReady ? 'visible' : 'hidden',
            }}
        />
    );
};

export default TVChartContainer;
