import React, { useState } from 'react'
import styles from './Welcome.module.scss'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import logo from '../../imgs/logo.png'
import laptop from '../../imgs/laptop-login.png';
import laptopLight from '../../imgs/laptop-light.png';
import { useTranslation } from 'react-i18next';
import LanguageButton from '../../components/LanguageButton';

export default function Welcome({setAccount}) {
    const nav = useNavigate();
    const {t} = useTranslation()
    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [error, setError] = useState('');
    const handleLoginChange = (event) => {
        setError('')
        const value = event.target.value;
        setLogin(value);
        if (!value) {
            setErrorLogin(true);
        } else {
            setErrorLogin(false);
        }
    };
    const handlePasswordChange = (event) => {
        setError('')
        const value = event.target.value;
        setPassword(value);
        if (!value) {
            setErrorPassword(true);
        } else {
            setErrorPassword(false);
        }
    };
    const validateForm = () => {
        if (!login) setErrorLogin(true);
        if (!password) setErrorPassword(true);
        return !errorLogin && !errorPassword;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(validateForm())
        if (validateForm()) {
            axios.post(process.env.REACT_APP_test_url + '/api/login', {
                Login: login,
                Password: password
            }).then((res) => {
                console.log(res);
                secureLocalStorage.setItem("token", res.data.token);
                secureLocalStorage.setItem("user", res.data.user)
                secureLocalStorage.setItem('accounts',res.data.accounts);
                secureLocalStorage.setItem('selected_account',res.data.account);
                setAccount(res.data.accounts[0]);
                nav('/terminal', { replace: true });
            }).catch((err) => {
                setError(err.response.data.message);
            })
        }
    };
    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    return (
        <div className={styles.page}>
<div className={styles.buttons}>
        <LanguageButton />
      </div>
            <div className={styles.login}>
                <div className={styles.login_form}>
                    <div className={styles.logo}>
                        <img src={logo} alt="Logo" style={{ width: '60px' }} />
                        Diamond Trade
                    </div>
                    <div className={styles.body} onKeyDown={(event) => handleEnter(event)}>
                        <div className={styles.sign_in}>
                            <h1>{t("Welcome")}</h1>
                            <p>{t("Start trade now!")}</p>
                        </div>
                        <div className={styles.Button}>
                            <Link to='/login'>{t('Trade')}</Link>
                        </div>
                    </div>
                    <div className={styles.Register}>
                        {/* <p>No account?</p>
                        <Link to={'/register'}>Sign up</Link> */}
                    </div>
                </div>
                <div className={styles.bg_laptop}>
                    {secureLocalStorage.getItem('data-theme') === 'dark'?
                    <img src={laptop} alt='laptop' width={'100%'} height={'100%'} />:
                    <img src={laptopLight} alt='laptop' width={'100%'} height={'100%'} />}
                </div>
            </div>
        </div>
    )
}
